
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS } from '../../location-availability-filters.service';

@Component({
    components: { CustomSelect },
})
export default class LocationsLokFilter extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    get items(): Item[] {
        const { availableLors } = this.lAvailabilityFiltersService;
        return availableLors.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        return this.lAvailabilityFiltersService.lor;
    }

    set currentValue(value) {
        if (value) {
            this.lAvailabilityFiltersService.lor = value;
        }
    }
}
