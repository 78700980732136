

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class LocationAvailabilityProviderFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;

    get currentValue() {
        return (this.lAvailabilityFiltersService.providers || [])
            .map(provider => ({ name: provider, value: provider }));
    }
    set currentValue(value: { name: string, value: string }[]) {
        this.lAvailabilityFiltersService.providers = value.map(provider => provider.value);
    }

    get options() {
        const { providersList } = this.lAvailabilityFiltersService;

        return providersList.map(value => ({
            name: value,
            value,
        }));
    }
}
