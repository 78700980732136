
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import LocationAvailabilityTable from '../components/location-availability-table.vue';
import LocationAvailabilityActions from '../components/location-availability-actions.vue';
import LocationAvailabilityTooltip from '../components/location-availability-tooltip.vue';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        LocationAvailabilityActions,
        LocationAvailabilityTable,
        LocationAvailabilityTooltip,
    },
})
export default class LocationAvailabilityPage extends Vue {
    @inject(UserServiceS) userService!: UserService;
}
