
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class LocationAvailabilityToggle extends Vue {
    @Prop({ required: true, type: Boolean })
    value!: boolean;

    handleChange(checked: boolean) {
        this.$emit('change', checked);
        this.$emit('input', checked);
    }
}
